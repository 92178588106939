import topHeader from "./assests/top.png";
import "./App.css";
import fb from "./assests/fb.png";
function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={topHeader} className="App-logo" alt="logo" />
        <div
          style={{
            height: "100%",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <p>
            <a href="https://www.facebook.com/ngoaingutanvan">
              <img src={fb} className="fb-icon" alt="logo" />
            </a>{" "}
          </p>
          <div>
            <a
              href="https://www.facebook.com/ngoaingutanvan"
              style={{ color: "#FF3861", fontWeight: 500, fontSize: "40px" }}
            >
              Hệ Thống Giáo Dục Tân Văn
            </a>
            <p
              style={{
                color: "black",
                fontSize: "20px",
                margin: "0",
                lineHeight: "30px",
              }}
            >
              <i
                class="fa fa-arrow-circle-o-right"
                style={{ marginRight: "8px" }}
              ></i>
              Ho Chi Minh City, Vietnam
            </p>
            <p
              style={{
                color: "black",
                fontSize: "20px",
                margin: "0",
                lineHeight: "30px",
              }}
            >
              <i class="fa fa-phone" style={{ marginRight: "8px" }}></i>091 829
              13 13
            </p>
          </div>
        </div>
        <div
          style={{
            background: "#25408f",
            width: "100%",
            height: "60px",
            marginTop: "auto",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <p
            style={{
              marginLeft: "auto",
              marginRight: "32px",
              fontSize: "18px",
            }}
          >
            Copyright © 1995 Tân Văn. All rights reserved.
          </p>
        </div>
      </header>
    </div>
  );
}

export default App;
